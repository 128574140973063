import React, { useState } from "react";
import { Link } from "react-router-dom";
import useDocumentTitle from "../../components/useDocumentTitle";
import FancyTextBlock22 from "../../components/fancy-text-block/FancyTextBlock22";
import HeaderLandingNoteTaking from "../../components/header/landing/HeaderLandingNoteTaking";
import HeroBannerSeven from "../../components/hero-banner/HeroBannerSeven";
import PricingSix from "../../components/pricing/pricing-six/Pricing";
import TestimonialSix from "../../components/testimonial/TestimonialSix";
import CopyRightThree from "../../components/footer/CopyRightThree";
import FooterSeven from "../../components/footer/FooterSeven";
import FancyFeatureSeventeen from "../../components/features/FancyFeatureSeventeen";
import DocAsTabs from "../../components/block-slider/DocAsTabs";

const NoteTakingLanding = () => {
  useDocumentTitle(
    "Tailored.Team | We are building dedicated team of proffesionals to help you tackle any challange. "
  );
  // For header select menu
  const [click1, setClick1] = useState(false);
  const handleClick1 = () => setClick1(!click1);
  return (
    <div className="main-page-wrapper font-gordita">
      <HeaderLandingNoteTaking />
      {/* End Header Landing Note Talking */}

      {/* 	=============================================
				Theme Hero Banner
			==============================================  */}
      <HeroBannerSeven />

      {/* =============================================
				Fancy Feature Fifteen
			==============================================  */}
      <div className="fancy-feature-fifteen pt-120 md-pt-80" id="product">
        <div className="bg-wrapper">
          <div className="container">
            <div className="sldier-wrapper">
              <DocAsTabs />
            </div>
            {/* /.sldier-wrapper */}
          </div>
          <img
            src="images/shape/168.svg"
            alt="shape"
            className="shapes shape-one"
          />
          <img
            src="images/shape/169.svg"
            alt="shape"
            className="shapes shape-two"
          />
        </div>
        {/* /.bg-wrapper */}
      </div>
      {/*  /.fancy-feature-fifteen */}

      {/* 	=============================================
				Fancy Feature Sixteen
			==============================================  */}
      <div className="fancy-feature-sixteen pt-200 md-pt-100" id="features">
        <div className="container">
          <div className="block-style-eighteen">
            <div className="row align-items-center">
              <div
                className="col-lg-5"
                data-aos="fade-right"
                data-aos-duration="1200"
              >
                <div className="text-wrapper">
                  <h6>Operate Efficiently</h6>
                  <h3 className="title">
                  Get instant visibility into all your <span>team work.</span>
                  </h3>
                  <p>
                    Assign tasks, share notes, and important information between all team members. Check the workload assigned to each team member.  
                  </p>
                </div>
                {/*  /.text-wrapper */}
              </div>
              <div
                className="col-lg-7"
                data-aos="fade-left"
                data-aos-duration="1200"
              >
                <div
                  className="
                    screen-holder-three
                    d-flex
                    align-items-center
                    justify-content-center
                  "
                >
                  <img src="images/assets/ils_11.svg" alt="screen" />
                </div>
              </div>
            </div>
          </div>
          {/* /.block-style-eighteen */}

          <div className="block-style-eighteen mt-200 pt-50 md-mt-80">
            <div className="row align-items-center">
              <div
                className="col-lg-5 order-lg-last"
                data-aos="fade-left"
                data-aos-duration="1200"
              >
                <div className="text-wrapper">
                  <h6>Track Your Progress</h6>
                  <h3 className="title">
                  Real-time project status<span> monitoring.</span>
                  </h3>
                  <p>
                    Identify project lags and keep them under control. Check project execution proccess and plan your next steps with ease.
                  </p>
                </div>
                {/* /.text-wrapper */}
              </div>
              <div
                className="col-lg-7 order-lg-first"
                data-aos="fade-right"
                data-aos-duration="1200"
              >
                <div className="screen-holder-two">
                  <img src="images/assets/ils_10.svg" alt="screen" />
                </div>
              </div>
            </div>
          </div>
          {/* /.block-style-eighteen */}

          <div className="block-style-eighteen mt-200 pt-50 md-mt-80">
            <div className="row align-items-center">
              <div
                className="col-lg-5"
                data-aos="fade-right"
                data-aos-duration="1200"
              >
                <div className="text-wrapper">
                  <h6>Note & Docs</h6>
                  <h3 className="title">
                    Seamless <span>File-Transfer</span>.
                  </h3>
                  <p>
                    Share project files and documents with team members without leaving the app. Reliable, fast file transfer will make your daily operations smooth.
                  </p>
                </div>
                {/* /.text-wrapper */}
              </div>
              <div
                className="col-lg-7"
                data-aos="fade-left"
                data-aos-duration="1200"
              >
                <div
                  className="
                    screen-holder-three
                    d-flex
                    align-items-center
                    justify-content-center
                  "
                >
                  <img src="images/assets/filesharing.png" alt="screen" />
                </div>
              </div>
            </div>
          </div>
          {/* /.block-style-eighteen */}
        </div>
      </div>
      {/*  /.fancy-feature-sixteen */}

      {/* =====================================================
				Fancy Text Block Twenty Two
			===================================================== */}
      <div
        className="fancy-text-block-twentyTwo lg-container pt-200 md-pt-120"
        id="why_us"
      >
        <div className="container">
          <FancyTextBlock22 />
        </div>
      </div>
      {/* /.fancy-text-block-twentyTwo */}

      {/* =====================================================
				Client Feedback Slider Six
			===================================================== */}
      <div
        className="client-feedback-slider-six pt-200 md-pt-120"
        id="feedback"
      >
        <div className="inner-container">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <img src="images/icon/100.svg" alt="icon" className="m-auto" />
                <div className="title-style-seven text-center mt-20">
                  <h2>
                    What does our <span>users say</span>?
                  </h2>
                  <p>User experience and customer satisfaction is in top 5 priorities list. <br/>Excelence, collaboration and working together are the next 3.</p>
                </div>
                {/* /.title-style-six  */}
              </div>
            </div>
          </div>
          <div className="clientSliderSix">
            <TestimonialSix />
          </div>
        </div>
        {/* /.inner-container */}
      </div>
      {/* /.client-feedback-slider-six */}

      {/* 	=====================================================
				Fancy Short Banner Eight
			===================================================== */}
      <div className="fancy-short-banner-eight mt-170 md-mt-80">
        <div className="container">
          <div className="row">
            <div
              className="col-xl-8 col-lg-11 m-auto"
              data-aos="fade-up"
              data-aos-duration="1200"
            >
              <div className="title-style-seven text-center">
                <h2>
                  <span>200k+</span> Customers have our deski.Try it now!
                </h2>
                <p>Try it risk free — we don’t charge cancellation fees.</p>
              </div>
              {/*  /.title-style-six */}
            </div>
          </div>
          <div data-aos="fade-up" data-aos-duration="1200" data-aos-delay="150">
            <div
              className={
                click1 ? "dropdown download-btn show" : "dropdown download-btn"
              }
            >
              <button
                className="dropdown-toggle"
                onClick={handleClick1}
                type="button"
              >
                Get Deski app
              </button>
              <div className={click1 ? "dropdown-menu  show" : "dropdown-menu"}>
                <a
                  className="dropdown-item d-flex align-items-center"
                  href="#"
                  onClick={handleClick1}
                >
                  <img src="images/icon/103.svg" alt="icon" />
                  <span>IOS & Android</span>
                </a>
                <a
                  className="dropdown-item d-flex align-items-center"
                  href="#"
                  onClick={handleClick1}
                >
                  <img src="images/icon/104.svg" alt="icon" />
                  <span>Mac & Windows</span>
                </a>
              </div>
            </div>
          </div>
        </div>
        {/*  /.container */}
        <img
          src="images/shape/171.svg"
          alt="shape"
          className="shapes shape-one"
        />
        <img
          src="images/shape/172.svg"
          alt="shape"
          className="shapes shape-two"
        />
      </div>
      {/*  /.fancy-short-banner-eight */}

      {/* 			=====================================================
				Footer Style Seven
			===================================================== */}
      <footer className="theme-footer-seven mt-120 md-mt-100">
        <div className="lg-container">
          <div className="container">
            <FooterSeven />
          </div>

          <div className="container">
            <div className="bottom-footer">
              <CopyRightThree />
            </div>
          </div>
        </div>
        {/* /.lg-container */}
      </footer>
      {/* /.theme-footer-seven */}
    </div>
  );
};

export default NoteTakingLanding;
