import React from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { Link } from "react-router-dom";

const TabListContent = [
  "All",
  "About Us",
  "Portfolio",
  "Feature & Team",
  "Pricing",
  "Doc",
  "Blog",
  "Contact Us",
  "Others",
];

const allIneerPages = [
  { img: "ip-01", routerPath: "/about-pm" },
  { img: "ip-02", routerPath: "/about-cs" },
  { img: "ip-03", routerPath: "/about-doc" },
  { img: "ip-04", routerPath: "/about-eo" },
  { img: "ip-05", routerPath: "/solution-management" },
  { img: "ip-06", routerPath: "/product-customer-support" },
  { img: "ip-07", routerPath: "/features-customer-support" },
  { img: "ip-38", routerPath: "/classic-style" },
  { img: "ip-39", routerPath: "/grid-two-col" },
  { img: "ip-40", routerPath: "/grid-three-col" },
  { img: "ip-41", routerPath: "/gallery-slider" },
  { img: "ip-42", routerPath: "/grid-single" },
  { img: "ip-43", routerPath: "/portfolio-details-v1" },
  { img: "ip-30", routerPath: "/team-1" },
  { img: "ip-31", routerPath: "/team-3" },
  { img: "ip-34", routerPath: "/team-4" },
  { img: "ip-35", routerPath: "/team-5" },
  { img: "ip-36", routerPath: "/team-6" },
  { img: "ip-37", routerPath: "/team-2" },
  { img: "ip-32", routerPath: "/team-details-v1" },
  { img: "ip-33", routerPath: "/team-details-v2" },
  { img: "ip-08", routerPath: "/contact-pm" },
  { img: "ip-09", routerPath: "/contact-cs" },
  { img: "ip-10", routerPath: "/contact-eo" },
  { img: "ip-11", routerPath: "/contact-doc" },
  { img: "ip-12", routerPath: "/pricing-pm" },
  { img: "ip-13", routerPath: "/pricing-cs" },
  { img: "ip-14", routerPath: "/pricing-eo" },
  { img: "ip-15", routerPath: "/doc-full-width-banner" },
  { img: "ip-16", routerPath: "/doc-full-width" },
  { img: "ip-17", routerPath: "/doc-box" },
  { img: "ip-18", routerPath: "/doc-box-with-banner" },
  { img: "changelog", routerPath: "/changelog" },
  { img: "ip-19", routerPath: "/blog-v1" },
  { img: "ip-19-01", routerPath: "/blog-v5" },
  { img: "ip-20", routerPath: "/blog-v2" },
  { img: "ip-21", routerPath: "/blog-v3" },
  { img: "ip-22", routerPath: "/blog-v4" },
  { img: "ip-23", routerPath: "/blog-v6" },
  { img: "ip-23_01", routerPath: "/blog-details" },
  { img: "ip-24", routerPath: "/faq" },
  { img: "ip-25", routerPath: "/faq-details" },
  { img: "ip-26", routerPath: "/terms-conditions" },
  { img: "ip-27", routerPath: "/login" },
  { img: "ip-28", routerPath: "/signup" },
  { img: "ip-29", routerPath: "/404" },
];

const aboutUs = [
  { img: "ip-01", routerPath: "/about-pm" },
  { img: "ip-02", routerPath: "/about-cs" },
  { img: "ip-03", routerPath: "/about-doc" },
  { img: "ip-04", routerPath: "/about-eo" },
];
const portfolio = [
  { img: "ip-38", routerPath: "/classic-style" },
  { img: "ip-39", routerPath: "/grid-two-col" },
  { img: "ip-40", routerPath: "/grid-three-col" },
  { img: "ip-41", routerPath: "/gallery-slider" },
  { img: "ip-42", routerPath: "/grid-single" },
  { img: "ip-43", routerPath: "/portfolio-details-v1" },
];
const featureTeam = [
  { img: "ip-05", routerPath: "/solution-management" },
  { img: "ip-06", routerPath: "/product-customer-support" },
  { img: "ip-07", routerPath: "/features-customer-support" },
  { img: "ip-30", routerPath: "/team-1" },
  { img: "ip-31", routerPath: "/team-3" },
  { img: "ip-34", routerPath: "/team-4" },
  { img: "ip-35", routerPath: "/team-5" },
  { img: "ip-36", routerPath: "/team-6" },
  { img: "ip-37", routerPath: "/team-2" },
  { img: "ip-32", routerPath: "/team-details-v1" },
  { img: "ip-33", routerPath: "/team-details-v2" },
];
const pricing = [
  { img: "ip-12", routerPath: "/pricing-pm" },
  { img: "ip-13", routerPath: "/pricing-cs" },
  { img: "ip-14", routerPath: "/pricing-eo" },
];
const doc = [
  { img: "ip-15", routerPath: "/doc-full-width-banner" },
  { img: "ip-16", routerPath: "/doc-full-width" },
  { img: "ip-17", routerPath: "/doc-box" },
  { img: "ip-18", routerPath: "/doc-box-with-banner" },
  { img: "changelog", routerPath: "/changelog" },
];
const blog = [
  { img: "ip-19", routerPath: "/blog-v1" },
  { img: "ip-19-01", routerPath: "/blog-v5" },
  { img: "ip-20", routerPath: "/blog-v2" },
  { img: "ip-21", routerPath: "/blog-v3" },
  { img: "ip-22", routerPath: "/blog-v4" },
  { img: "ip-23", routerPath: "/blog-v6" },
  { img: "ip-23_01", routerPath: "/blog-details" },
];
const contactUs = [
  { img: "ip-08", routerPath: "/contact-pm" },
  { img: "ip-09", routerPath: "/contact-cs" },
  { img: "ip-10", routerPath: "/contact-eo" },
  { img: "ip-11", routerPath: "/contact-doc" },
];
const other = [
  { img: "ip-24", routerPath: "/faq" },
  { img: "ip-25", routerPath: "/faq-details" },
  { img: "ip-26", routerPath: "/terms-conditions" },
  { img: "ip-27", routerPath: "/login" },
  { img: "ip-28", routerPath: "/signup" },
  { img: "ip-29", routerPath: "/404" },
];

const InnerPages = () => {
  return (
    <div className="wrapper">
      <Tabs>
        <TabList className="controls d-flex flex-wrap justify-content-center">
          {TabListContent.map((tab, i) => (
            <Tab key={i}>
              <button type="button" className="control">
                {tab}
              </button>
            </Tab>
          ))}
        </TabList>
        {/* End TabList */}

        <TabPanel>
          <div className="mixitUp-containerTwo">
            {allIneerPages.map((item, i) => (
              <div className="mix" key={i}>
                <Link
                  to={item.routerPath}
                  className="img-meta"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={`images/preview/inner-page-demo/${item.img}.jpg`}
                    alt="inner page demo"
                  />
                </Link>
              </div>
            ))}
          </div>
        </TabPanel>
        {/* End All */}

        <TabPanel>
          <div className="mixitUp-containerTwo">
            {aboutUs.map((item, i) => (
              <div className="mix" key={i}>
                <Link
                  to={item.routerPath}
                  className="img-meta"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={`images/preview/inner-page-demo/${item.img}.jpg`}
                    alt="inner page demo"
                  />
                </Link>
              </div>
            ))}
          </div>
        </TabPanel>
        {/* End About Us */}

        <TabPanel>
          <div className="mixitUp-containerTwo">
            {portfolio.map((item, i) => (
              <div className="mix" key={i}>
                <Link
                  to={item.routerPath}
                  className="img-meta"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={`images/preview/inner-page-demo/${item.img}.jpg`}
                    alt="inner page demo"
                  />
                </Link>
              </div>
            ))}
          </div>
        </TabPanel>
        {/* End Portfolio */}

        <TabPanel>
          <div className="mixitUp-containerTwo">
            {featureTeam.map((item, i) => (
              <div className="mix" key={i}>
                <Link
                  to={item.routerPath}
                  className="img-meta"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={`images/preview/inner-page-demo/${item.img}.jpg`}
                    alt="inner page demo"
                  />
                </Link>
              </div>
            ))}
          </div>
        </TabPanel>
        {/* End Features & Team */}

        <TabPanel>
          <div className="mixitUp-containerTwo">
            {pricing.map((item, i) => (
              <div className="mix" key={i}>
                <Link
                  to={item.routerPath}
                  className="img-meta"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={`images/preview/inner-page-demo/${item.img}.jpg`}
                    alt="inner page demo"
                  />
                </Link>
              </div>
            ))}
          </div>
        </TabPanel>
        {/* End Pricing */}

        <TabPanel>
          <div className="mixitUp-containerTwo">
            {doc.map((item, i) => (
              <div className="mix" key={i}>
                <Link
                  to={item.routerPath}
                  className="img-meta"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={`images/preview/inner-page-demo/${item.img}.jpg`}
                    alt="inner page demo"
                  />
                </Link>
              </div>
            ))}
          </div>
        </TabPanel>
        {/* End Doc */}

        <TabPanel>
          <div className="mixitUp-containerTwo">
            {blog.map((item, i) => (
              <div className="mix" key={i}>
                <Link
                  to={item.routerPath}
                  className="img-meta"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={`images/preview/inner-page-demo/${item.img}.jpg`}
                    alt="inner page demo"
                  />
                </Link>
              </div>
            ))}
          </div>
        </TabPanel>
        {/* End Blog */}

        <TabPanel>
          <div className="mixitUp-containerTwo">
            {contactUs.map((item, i) => (
              <div className="mix" key={i}>
                <Link
                  to={item.routerPath}
                  className="img-meta"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={`images/preview/inner-page-demo/${item.img}.jpg`}
                    alt="inner page demo"
                  />
                </Link>
              </div>
            ))}
          </div>
        </TabPanel>
        {/* End Contact US */}

        <TabPanel>
          <div className="mixitUp-containerTwo">
            {other.map((item, i) => (
              <div className="mix" key={i}>
                <Link
                  to={item.routerPath}
                  className="img-meta"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={`images/preview/inner-page-demo/${item.img}.jpg`}
                    alt="inner page demo"
                  />
                </Link>
              </div>
            ))}
          </div>
        </TabPanel>
        {/* End Others */}
      </Tabs>
    </div>
  );
};

export default InnerPages;
