import React, { useState } from "react";
import useDocumentTitle from "../../../components/useDocumentTitle";
import HeaderThree from "../../../components/header/HeaderThree";
import DocSidebarContent from "./DocSidebarContent";

const Changelog = () => {
  useDocumentTitle("Changelog || Deski-Saas & Software React Template");
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);
  return (
    <div>
      <HeaderThree />
      {/* End Header */}

      {/* 	=============================================
				Fancy Hero Five
			==============================================  */}
      <div className="fancy-hero-five">
        <div className="bg-wrapper">
          <div className="container">
            <div className="text-center">
              <h1 className="heading">Changelog</h1>
              <p className="sub-heading space-xs">
                Find the template Changelog here.
              </p>
            </div>
          </div>
        </div>
        {/* /.bg-wrapper */}
      </div>
      {/* /.fancy-hero-five */}

      {/* =============================================
            Documentation
        ==============================================  */}

      <div className="doc-container mt-70 sm-m0">
        <div className="container">
          <div className="row flex-xl-nowrap no-gutters">
            {/* <!-- ****************************** DOC MAIN BODY ********************************* --> */}
            <main className="col-12 doc-main-body">
              <h5 className="font-rubik mb-20">
                Release Date: <mark>30/10/2021</mark>
              </h5>
              <div className="mark-blue">
                <pre>
                  __Page Added: <br /> 1) Changelog Page Added <br /> 2) Blog
                  Variation 6 Added <br /> 3) Inner Sections Gallery Added for
                  Intro Page
                </pre>
              </div>
              {/* <!-- /.mark-blue --> */}

              <h5 className="font-rubik mb-20">
                Release Date: <mark>24/10/2021</mark>
              </h5>
              <div className="mark-blue">
                <pre>
                  __Page Added:: <br /> 1) Portfolio Classic Style Added
                  <br /> 2) Portfolio Grid 2 Columns Added <br /> 3) Portfolio
                  Grid 3 Columns Added <br /> 4) Portfolio Gallery Slider Added{" "}
                  <br /> 5) Portfolio Grid Single Added <br /> 6) Portfolio
                  Classic Details Added
                </pre>
              </div>
              {/* <!-- /.mark-blue --> */}

              <h5 className="font-rubik mb-20">
                Release Date: <mark>20/10/2021</mark>
              </h5>
              <div className="mark-blue">
                <pre>
                  __Page Added: <br /> 1) Doc Signature Landing Page Added{" "}
                </pre>
              </div>
              {/* <!-- /.mark-blue --> */}

              <h5 className="font-rubik mb-20">
                Release Date: <mark>18/10/2021</mark>
              </h5>
              <div className="mark-blue">
                <pre>
                  __Fixed: <br /> 1) Dark Version Landing Scrolling Issue Fixed{" "}
                  <br /> 2) Form Warning Issue Fixed <br /> 3) Meta Title
                  Missing Issue Fixed
                </pre>
              </div>
              {/* <!-- /.mark-blue --> */}

              <h5 className="font-rubik mb-20">
                Release Date: <mark>04/10/2021</mark>
              </h5>
              <div className="mark-blue">
                <pre>
                  __Fixed: <br /> 1) Landing Page Responsive Issues Fixed <br />{" "}
                  2) Preview Updated
                </pre>
              </div>
              {/* <!-- /.mark-blue --> */}

              <h5 className="font-rubik mb-20">
                Release Date: <mark>25/09/2021</mark>
              </h5>
              <div className="mark-blue">
                <pre>
                  __Fixed: <br /> 1) Landing Page Spacing Issues Fixed <br /> 2)
                  Preview Page Issue Fixed <br /> 3) OnePage Nav Issues Fixed{" "}
                </pre>
              </div>
              {/* <!-- /.mark-blue --> */}

              <h5 className="font-rubik mb-20">
                Release Date: <mark>20/09/2021</mark>
              </h5>
              <div className="mark-blue">
                <pre>
                  __Page Added: <br /> 1) App Landing Page Added <br /> 2)
                  Preview Page updated <br /> 3) Responsive issue fixed <br />{" "}
                  4) Documentation Updated
                </pre>
              </div>
              {/* <!-- /.mark-blue --> */}

              <h5 className="font-rubik mb-20">
                Release Date: <mark>17/09/2021</mark>
              </h5>
              <div className="mark-blue">
                <pre>#First Published</pre>
              </div>
              {/* <!-- /.mark-blue --> */}
            </main>
            {/* <!-- /.doc-main-body --> */}
          </div>
        </div>
      </div>
      {/* <!-- /.doc-container --> */}
    </div>
  );
};

export default Changelog;
