import React from "react";
import FormDemo from "../../components/form/FormDemo";
import BrandFour from "../brand/BrandFour";

const HeroBannerSeven = () => {
  return (
    <div className="hero-banner-seven lg-container">
      <div className="container">
        <div className="illustration-container">
          <img src="images/assets/ils_21.svg" alt="ils" />
        </div>
        {/* End .illustration-container */}

        <div className="row">
          <div className="col-lg-6">
            <h1 className="hero-heading">
            Get Your Work <span>Done</span>.<span>Better</span>. <span>Faster</span>.
            </h1>
            <p className="hero-sub-heading">
            We are building dedicated team of proffesionals to help you tackle any challenge. Tailored to your needs. 
            </p>
            <FormDemo />
            <p className="term-text">
              For organizations & startups — small, medium, enterprise solutions
            </p>
          </div>
        </div>
        {/* End .row */}
      </div>
      {/* End .container */}

      <div className="partner-slider-two mt-130 md-mt-80">
        <div className="container">
          <p className="text-center">
            Over <span>80% of businesses</span> worldwide are in a need of a dedicated, tailored team.
          </p>
          <div className="partnerSliderTwo">
            <BrandFour />
          </div>
        </div>
      </div>
      {/* End .partner-slider-two */}

      {/* /.partner-slider-two  */}
    </div>
  );
};

export default HeroBannerSeven;
